
import Logo from '@/common/components/Logo.vue';

export default {
  methods: {
    logout() {
      (this as any).$store.dispatch('logout')
    }
  },
  components: {
    Logo
  }
}
