
import Vue from 'vue';
import axios from "axios";

export default Vue.extend({
  name: 'App',
  computed: {
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    }
  },
  methods: {
    async verifyToken() {
      try {
        const { data = {} } = await axios.post('/api/auth/verify-token');
        const { user, token, expiredAt } = data;

        if (token) {
          this.$store.commit(
              'setAuthData',
              { user, token, expiredAt }
          );
          this.$store.commit('setIsAuthenticated', true);
          this.$router.push('/');
          return;
        }

        this.$store.commit('setIsAuthenticated', false);
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.verifyToken();
  }
});
