import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Dashboard from '@/views/dashboard';
import Login from '@/views/login';
import NotFound from '@/views/not-found';
import store from "@/store";

Vue.use(VueRouter)

const dashboard = [
  {
    path: '/',
    name: 'branch',
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/content/branch/Branch.vue')
  },
  {
    path: '/offer',
    name: 'offer',
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/content/offer/Offer.vue')
  },
  {
    path: '/sharing-documents',
    name: 'sharing-documents',
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/content/sharing-documents/index.vue')
  },
  {
    path: '/validate',
    name: 'validate',
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/content/validate/index.vue')
  },
  {
    path: '/sign',
    name: 'sign-documents',
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/content/sign-documents/index.vue')
  }
]

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Dashboard,
    children: dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (document.cookie.includes('XSRF-TOKEN') || store.state.isAuthenticated) {
      next();
      return
    }

    next('/login');
  } else {
    next();
  }
});

export default router
