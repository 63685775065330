import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import router from "@/router";

Vue.use(Vuex)
const initialAuth = {
  token: null,
  user: null,
  expiredAt: null
};

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    loading: false,
    ...initialAuth
  },
  getters: {
    getAuthToken({ token }) {
      return token;
    }
  },
  mutations: {
    setIsAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    },
    setAuthData(state, { token = null, user = null, expiredAt = null}) {
      state.token = token;
      state.user = user;
      state.expiredAt = expiredAt;
    }
  },
  actions: {
    async logout({ commit }) {
      commit('setIsAuthenticated', false);
      commit('setAuthData', initialAuth);

      await axios.get('/api/auth/logout');
      router.push('/login');
    }
  }
})
