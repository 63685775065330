import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"inspire"}},[_c(VMain,[_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"md8":"","lg5":"","xl5":""}},[_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("SDK login form")])],1),_c(VCardText,[_c(VForm,[_c(VTextField,{attrs:{"prepend-icon":"person","name":"username","label":"Username","type":"text"},model:{value:(_vm.credential.username),callback:function ($$v) {_vm.$set(_vm.credential, "username", $$v)},expression:"credential.username"}}),_c(VTextField,{attrs:{"prepend-icon":"lock","id":"password","name":"password","label":"Password","type":"password"},model:{value:(_vm.credential.password),callback:function ($$v) {_vm.$set(_vm.credential, "password", $$v)},expression:"credential.password"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.signin}},[_vm._v("Login")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }