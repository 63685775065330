
import axios from "axios";

export default {
  data: () => ({
    credential: {
      username: '',
      password: ''
    }
  }),
  methods: {
    async signin() {
      try {
        const self = (this as any);

        const { data } = await axios.post(
            '/api/auth/signin',
            self.credential
        )
        const { user, token, expiredAt } = data;

        if (token) {
          self.$store.commit(
              'setAuthData',
              { user, token, expiredAt }
          );
          self.$store.commit('setIsAuthenticated', true);
          self.$router.push('/');
          return;
        }
      } catch (e) {
        console.log(e, 'e');
      }
    }
  }
}
