
import Vue from 'vue';
import Logo from "@/common/components/Logo.vue";
import Header from "@/views/dashboard/header";
import Tabs from "@/views/dashboard/tabs";

export default Vue.extend({
  components: { Header, Logo, Tabs },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    }
  }
})
